/* .leaflet-div-icon {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.leaflet-interactive:hover {
    fill-opacity: 1;
}

.leaflet__room-tooltip {
    background: transparent;
    padding: 0;
    border: 0;
    box-shadow: none;
    font-weight: 600;
}

.leaflet__room-tooltip:before {
    display: none;
}*/
.mapboxgl-popup {
  max-width: unset !important;
  font-size: 13px;
}
.mapboxgl-popup-content {
  padding: 0;
}

.mapboxgl-popup-close-button {
  font-size: 22px;
}

.map-marker__popup {
  color: black;
  font-family: "Open Sans", sans-serif;
  min-width: 170px;
  padding: 15px;
}

.map-marker__popup.transport-popup {
  min-width: 220px;
  max-width: unset;
}

.map-marker__popup-title {
  font-weight: 700;
  font-size: 1.076em;
}

.map-marker__popup-subtitle {
}

.map-marker__line {
  margin: 7px 0;
  height: 0;
  border: 0;
  border-top: 1px solid #dfdfdf;
}

.map-marker__popup-btn {
  height: 35px;
  width: 100%;
  background: #111111;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  border-radius: 5px;
}

.map__wrapper {
  position: relative;
  height: calc(100vh - 10%);
  width: 100%;
}

.map__holder {
  height: 100%;
  width: 100%;
}

/* .map__active-features-holder {
    position: absolute;
    top: 10px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 50;
} */

.map__active-features {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.map__active-feature {
  min-height: 40px;
  margin-right: 0.25rem;
  background: white;
  color: black;
  border-radius: 0.25rem;
  padding: 0.5rem 0.8rem;
  font-size: 14px;
  border: 0;
  display: flex;
  white-space: nowrap;
  align-items: center;
  -webkit-box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
}

.map__active-feature-label {
  margin-right: 0.25rem;
}

.map__active-feature-title {
  font-weight: 700;
}

.map__active-feature-close-btn {
  font-weight: 700;
  margin-left: 0.75rem;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}

.map__feature-detail {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 1rem;
  background: white;
  -webkit-box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.07);
  color: black;
  min-width: 330px;
}

.map__controls-holder {
  position: absolute;
  padding: 10px;
  max-width: 100%;
  z-index: 1;
  width: 100%;
}

.map-searchbar__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .map__controls-holder {
    width: auto;
  }
  .map-searchbar__wrapper {
    max-width: 600px;
    min-width: 400px;
  }
}

@media (max-width: 75em) {
  .map__controls-holder {
    margin-top: 3.5em;
  }
}

@media screen and (min-width: 992px) {
  .map-searchbar__wrapper {
    min-width: 500px;
  }
}

.map-searchbar__input {
  position: relative;
  border: 0;
  border-radius: 5px;
  outline: none !important;
  padding: 0 1rem;
  width: 100%;
  height: 50px;
  -webkit-box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.07);
  z-index: 5050;
  font-size: 0.9375rem;
}

.map-searchbar__input-icon {
  position: absolute;
  right: 10px;
  height: 30px;
  color: #c0c0c0;
  z-index: 5060;
}

.map-searchbar__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
}

.map-searchbar__input-results-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.map-searchbar__results {
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #e5e7eb;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 5100;
  color: black;
  padding: 0.5rem;
}

/* transport lines */
.transport-line-labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.transport-line-label {
  width: 23px;
  height: 23px;
  border-radius: 4px;
  text-align: center;
  line-height: 23px;
  font-weight: bold;
  color: white;
  background: #6f7273;
  margin-right: 3px;
}

.transport-line-label--1 {
  background: #af9778;
}
.transport-line-label--2 {
  background: #d2005c;
}
.transport-line-label--3 {
  background: #55bace;
}
.transport-line-label--4 {
  background: #7477b0;
}
.transport-line-label--5 {
  background: #bfbfb9;
  color: black;
}
.transport-line-label--6 {
  background: #ea7b08;
}
.transport-line-label--7 {
  background: #5cbf14;
}
.transport-line-label--9 {
  background: #f5d907;
  color: black;
}
.transport-line-label--R1,
.transport-line-label--R2,
.transport-line-label--R3,
.transport-line-label--R4,
.transport-line-label--R5,
.transport-line-label--R6,
.transport-line-label--R7,
.transport-line-label--R8 {
  background: #0377ae;
}

.transport-line-label--10 {
  background: #d61a0c;
}
.transport-line-label--15 {
  background: #f99aa7;
}
.transport-line-label--16 {
  background: #6800a2;
}

.transport-line-label--19 {
  background: #026553;
}
.transport-line-label--23 {
  background: #0e8ccc;
}
.transport-line-label--27 {
  background: #2105a3;
}
.transport-line-label--34 {
  background: #710a6b;
}
.transport-line-label--36 {
  background: #7f581a;
}
.transport-line-label--71 {
  background: #81c7e5;
}
.transport-line-label--72 {
  background: #c6d640;
}

.transport-line-label--N1 {
  background: black;
  color: #fae013;
}
.transport-line-label--N2 {
  background: black;
  color: #ffbabd;
}
.transport-line-label--N3 {
  background: black;
  color: #ebf28a;
}
.transport-line-label--N4 {
  background: black;
  color: #e10585;
}
.transport-line-label--N5 {
  background: black;
  color: #b5f1bb;
}
.transport-line-label--N6 {
  background: black;
  color: #aedbf1;
}
.transport-line-label--N7 {
  background: black;
  color: #feae46;
}
